@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
    label.required:after {
        content: '*';
        @apply ml-1 text-red-500;
    }
}
#app {
    height: 100%;
}

